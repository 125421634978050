import { h } from 'preact';
import config from '../../config';
import { useState, useEffect, useCallback } from 'preact/hooks';
import { useStore } from '../store-adapter';
import { useTranslation } from '../../lib/i18n';
import style from './style';
import { useLanguage } from '../../lib/i18n';

export default function Footer() {
	const [lang, setLang] = useLanguage();

	const onSelect = useCallback(e => setLang(e.target.value), [setLang]);

	const impressumTitle = useTranslation('impressum');
	const agbTitle = useTranslation('agb');

	return (
		<footer class={style.footer}>
			<div class={style.inner}>
				{/*<p>*/}
				{/*	<label class={style.lang}>*/}
				{/*		Sprache:{' '}*/}
				{/*		<select value={lang || 'de'} onInput={onSelect}>*/}
				{/*			{Object.keys(config.languages).map(id => (*/}
				{/*				<option selected={id == lang} value={id}>*/}
				{/*					{config.languages[id]}*/}
				{/*				</option>*/}
				{/*			))}*/}
				{/*		</select>*/}
				{/*	</label>*/}
				{/*</p>*/}
				<div>
					<a href="/impressum">{impressumTitle}</a>
					{'  '}
					<a href="/agb">{agbTitle}</a>
				</div>
				<div>
					© 2021 - Eisenstadt-Vollreinigung - O. Eisenstadt
				</div>
			</div>
		</footer>
	);
}
