import { Component } from 'preact';
import style from './style';

export default class Phone extends Component {

	render({ number }) {
		const href = `tel: ${number}`;

		return (
			<div class={style.phone}>
				<a href={href}>{number}</a>
			</div>
		);
	}

}
